/* Default Light Theme */
:root {
  --background-color: #f8f9fa;
  --text-color: #343a40;
  --nav-link-color: #343a40;
  --nav-link-active-color: #5aa1bd;
  --border-color: #2a8ab8;
  --form-background-color: #f8f9fa;
  --form-text-color: #000000;
  --form-background-submitted-color: #47798d86;
  --form-text-submitted-color: #000000;
}

/* Dark Theme */
.dark-mode {
  --background-color: #343a40;
  --text-color: #f8f9fa;
  --nav-link-color: #5aa1bd;
  --nav-link-active-color: #2a8ab8;
  --border-color: #2a8ab8;
  --form-background-color: #343a40;
  --form-text-color: #f8f9fa;
  --form-background-submitted-color: #47798d86;
  --form-text-submitted-color: #f8f9fa;
}

/* Light Theme */
.light-mode {
  --background-color: #f8f9fa;
  --text-color: #343a40;
  --nav-link-color: #343a40;
  --nav-link-active-color: #5aa1bd;
  --border-color: #2a8ab8;
  --form-background-color: #f8f9fa;
  --form-text-color: #000000;
  --form-background-submitted-color: #47798d86;
  --form-text-submitted-color: #000000;
}

body {
  background-color: var(--background-color);
  font-family: 'Poppins', sans-serif;
  color: var(--text-color);
  height: 100%!important;  
}
.content {
  flex: 1;  /* This will make sure the content takes up available space */
}
div {
  caret-color: transparent; /* Odstr�ni blikaj�ci kurzor */
}
input {
  caret-color: var(--nav-link-color); 
}
footer {
  font-family: 'Indie Flower', cursive;
  font-size: 22px;
  color: var(--text-color);
}

/* UNLOGGED */
/* HomePage */
.homepage-container {
  margin-top: 100px;
  border-top: thick double var(--border-color);
  font-family: 'Poppins', sans-serif;
}
.login-side {
  border-left: thick double var(--border-color);
}

/* LOGGED */
/* navigation */
nav {
  border-bottom: thick double var(--border-color);
}
.nav-link {
  color: var(--nav-link-color);
  font-size: 20px;
  border: none!important;
  box-shadow: none!important;
  outline: none!important;
}
.nav-link:focus,
.nav-link:active,
.nav-link:hover {
  color: var(--nav-link-active-color);
  outline: none;
  border: none;
}
.nav-link.active {
  color: #2a8ab8!important; 
  font-weight: bold; 
  outline: none;
}

/* forms */
.task-rating-form, .task-dashboard-form, .history-dashboard {
  border: 3px double var(--border-color);
  border-radius: 5px;
  color: var(--text-color);
}

.task-rating-form table {
  background-color: var(--background-color);
  color: var(--text-color);
  text-transform: uppercase;
}
.task-rating-form table th, .task-rating-form table td {
  border: 1px solid #2a8bb86b;
  padding: 4px;
  text-align: center;
}
.task-rating-form form {
  background-color: var(--background-color);
  border-radius: 5px;
}


/* dashboard */ 
.h-buzzer-background {
  background-image: url("images/standing_elephant.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 180px;
}
.h-buzzer-background h1, .history-page h1, .history-page h2, .about-page h1, .settings-page h2 {
  text-shadow: 1px 1px  #417488bb;
  color: #5aa1bd!important;
  font-family: 'Indie Flower', cursive;
}
.task-rating-form {
  font-family: 'Indie Flower', cursive;
  font-size: 18px;
}
/* history */
.history-dashboard h2 {
  color: #2a8ab8; 
}
.history-tasks {
  font-family: 'Indie Flower', cursive;
  font-size: 34px;
  padding: 4px;
}
.average-circle {
  font-family: 'Indie Flower', cursive;
  font-size: 36px;
  border-radius: 75%;
  border: 1px solid #26995a;
}
.history-date {
  font-size: 14px;
}
/* about */
.about-page {
  max-width: 1040px;
}

/* settings */
.settings-page {
  max-width: 370px;
}
.setting-table {
  min-width: 370px;
  background-color: var(--background-color);
  color: var(--text-color);
}
.setting-table td {
  border-bottom: 1px solid #5aa1bd94;
  height: 46px;
}
.setting-table .form-control {
  max-height: 26px;
  background-color: var(--form-background-submitted-color);
}
.form-check-input:checked {
  background-color:#2a8ab8;
  border-color:#5aa1bd;
}
.form-check-input {
  background-color:#5aa1bd94;
  border-color:#2a8ab8;
}

@media (max-width: 768px) {
  .h-buzzer-background {
    min-height: 120px;
  }
  .h-buzzer-background h1 {
    text-align: right;
    margin-top: 12px!important;
  }
  .h-buzzer-background button {
    padding: 6px!important;
    font-size: 14px;
  }
  .homepage-container {
    margin-top: 0px;
  }
  .homepage-container .login-side {
    margin-top: 46px;
    border-top: thick double var(--border-color);
    border-left: none;
    height: 100%;
  }
  .task-rating-form {
    padding: 8px!important;
    margin: 8px!important;
  }
  .task-rating-form form {
    padding: 0px!important;
    margin: 0px!important;
  }
  .task-rating-form i {
    font-size: 16px!important;
  }
}